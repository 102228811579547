<template>
<el-dialog title="选择学校" :visible.sync="value" :close-on-click-modal="false" :before-close="dialogClose" width="1100px">
  <div style="width:100%">
    <div class="search-box" style="text-align:left;">
      <el-form inline size="small">
        <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="名称、编号" style="width:150px"></el-input>
        </el-form-item>
        <el-form-item label="类型">
           <ChooseSchoolType v-model="form.schoolTypeId" style="width:150px" :showAll="true"></ChooseSchoolType>
        </el-form-item>
        <el-form-item label="地区" prop="proId" >
            <ChooseArea ref="chooseArea" @changeArea="changeArea" :selectedLevel="4" :checkStrictly="true" style="width:350px" ></ChooseArea>
          </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>

      </el-form>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange"
    row-key="id"
     style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="名称" prop="name" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="地区" align="center" show-overflow-tooltip width="360">
        <template slot-scope="scope">
          <span >{{scope.row.proName+","+scope.row.cityName+','+scope.row.regName+","+scope.row.streetName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型" align="center" show-overflow-tooltip prop="schoolTypeName" width="150"></el-table-column>    
      <el-table-column label="业务员" align="center" prop="deliverUserName" width="120"></el-table-column> 
      <el-table-column label="状态" align="center" show-overflow-tooltip width="150">
        <template slot-scope="scope">
          <span >{{scope.row.status==1?'已合作':'未合作'}}</span>
        </template>
      </el-table-column>
     
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
    <el-row>
    <el-col :span="24" style="margin-top:50px;">
        <el-button @click="dialogClose">关闭</el-button>
        <el-button type="primary" @click="onSave">保存</el-button>
    </el-col>
    </el-row>
  </div>
</el-dialog>
</template>

<script>
import { getSchoolList } from '@/api/index.js'
import ChooseArea from '@/components/ChooseArea/index.vue'
import ChooseSchoolType from '@/components/ChooseSchoolType/index.vue'
export default {
  name: 'selectSchool',
  components: {ChooseArea,ChooseSchoolType},
  props:  ['value'],
  data() {
    return {
      showType: 0,
      editType: '',
      isAddDialog:false,
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        proId:'',
        cityId:'',
        regId:'',
        streetId:'',
        schoolTypeId:'',
        isShowChild:true
      },
      list: [],
      total: 0,
      selectSchoolList:[]
    }
    
  },
  created() {
    this.getList();
  },
  methods: {
    changeArea(item){
      this.form.proId = item.proId;
      this.form.cityId = item.cityId;
      this.form.regId = item.regId;
      this.form.streetId = item.streetId;
    },
    dialogClose() {
      this.$emit('input', false)
    },
    onSave(){
      if(this.selectSchoolList.length == 0){
        this.$message.error('请至少选择一个学校！');
        return;
      }
      this.$emit('okSelected',this.selectSchoolList)
    },
    handleSelectionChange(val){
      this.selectSchoolList = val;
    },
    getList() {
      getSchoolList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        for(let item of this.list){
          item.originalId = item.id;
        }
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
  }
}
</script>

<style>
</style>