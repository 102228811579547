<template>
<el-dialog title="设置征订书内容" :visible.sync="value" :close-on-click-modal="false" :before-close="dialogClose" width="700px">
  <div>
    <div class="content" id="remindDiv" ></div>
    <div class="save" >
      <el-button v-debounce="dialogClose" >关闭</el-button>
    	<el-button type="primary" v-debounce="save" v-if="edit" >保存</el-button>
    </div>
  </div>
</el-dialog>
        
</template>

<script>
import { getDefaultRemind } from '@/api/index.js'
import E from 'wangeditor'
import { baseURL } from '@/api/http';
var editor = null;
export default {
  name: 'remind',
  props:  ['value','notice','edit'],
  components: {  },
  data() {
    return {
      remind:''
    }     
    
  },
  created() {
    console.log('我是原来的',this.notice)
    // if(this.notice){
    //   this.initEditor(this.notice)
    // }else{
    //   this.getDefaultRemind();
    // }

    this.initEditor(this.notice)
   
  },
  mounted() {
    
  },
  beforeDestroy(){
    editor.destroy()
    editor = null;
    console.log('销毁编辑器')
  },
  methods: {
    dialogClose() {
      this.$emit('input', false)
    },
    save(){
      this.$emit('change', editor.txt.html())
    },
    async getDefaultRemind(){
      let param = {};      
      const res = await getDefaultRemind(param)
      if (res.code != 200) return
    	let defaultRemind = res.data;  		
			this.initEditor(defaultRemind);

    },
    initEditor(noticeHtml){
      this.$nextTick(() => {
        editor = new E('#remindDiv');
        editor.config.height = 450;
        editor.config.uploadImgServer = baseURL + '/uploadFile/upload';
        editor.config.uploadFileName = 'mfile'

        let headers = {
           'x-token': sessionStorage.getItem('x-token')
        }
        editor.config.uploadImgHeaders = headers;
				editor.config.uploadImgHooks ={
					customInsert:function(insertImg, result, editor){
 						const url = result.data.url;
          	insertImg(url)
					}
				}

        if(!this.edit){
          editor.config.menus = []
        }
				
				editor.config.showFullScreen = false

        editor.config.uploadImgMaxLength = 1
        editor.create();
        editor.txt.html(noticeHtml)
        if(!this.edit){
          editor.disable()
        }
				
      
    })
    }
  }
}
</script>

<style scoped lang="scss">
.title{
	width:550px;
	text-align:center;
	line-height:25px;
	font-size:16px;
	font-weight: bold;
	margin: auto;
}
.content{
	margin: auto;
	width:550px;
	margin-top:10px;
}

.save{
	width:550px;
	text-align:center;
	padding:10px;
	margin: auto;
}

</style>
